
<template>
    <b-container fluid>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $i18n.locale == 'bn' ? 'বিতরণ এন্ট্রি (বিদ্যমান)' : 'Distribution Import (Existing)' }}</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-button variant="success btn_add_new" class="m-2 btn-success" target="_blank" :href="incentiveGrantServiceBaseUrl + 'download-attachment?file=uploads/supplier_machinery_format.xlsx'">
                      <i class="fa fa-download" aria-hidden="true"></i>
                      {{ $t('irriPumpInformation.bulk_importer_format') }}
                      </b-button>
                      <b-button class="m-2" variant="primary btn_add_new" v-b-modal.modal-6 @click="resetId">
                        <i class="fas fa-plus"></i>{{ $t('farm_config.importer') }}
                      </b-button>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-6" size="lg" :title="$t('farm_config.importer')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Importer :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Importer from './Importer'
import { incentiveGrantServiceBaseUrl } from '@/config/api_config'

export default {
  components: {
    Importer
  },
  data () {
    return {
        incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
        rows: [],
        item: ''
    }
  },
  created () {
  }
}
</script>
