<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                      <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                          <b-row>
                                            <b-col lg="7" sm="12">
                                              <ValidationProvider name="Attachment" vid="attachment" rules="required">
                                                <b-form-group class="row" label-cols-sm="3" label-for="attachment" slot-scope="{ valid, errors }">
                                                  <template v-slot:label>
                                                    {{ $t('farm_config.attachment') }} <span class="text-danger">*</span>
                                                  </template>
                                                  <b-form-file
                                                    id="attachment"
                                                    v-model="machineryFarmer.attachment"
                                                    :placeholder="$t('common_config.file_chosen')"
                                                    @change="onFileChange"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                  </b-form-file>
                                                  <div class="invalid-feedback">
                                                    {{ errors[0] }}
                                                  </div>
                                                </b-form-group>
                                              </ValidationProvider>
                                            </b-col>
                                          </b-row>
                                          <!-- duplicateRows -->
                                          <b-row>
                                            <h4 class="mb-1 text-center" v-if="duplicateDataShow">{{ $i18n.locale === 'en' ? 'Duplicate Records' : 'ডুপ্লিকেট রেকর্ড' }}</h4>
                                            <b-table-simple bordered v-if="duplicateDataShow">
                                                <b-tr>
                                                    <!-- <b-th>{{ $t('globalTrans.upazila') }}</b-th> -->
                                                    <b-th>{{ $t('dae_grant_allocation_distribution.farmer_name') }}</b-th>
                                                    <b-th>{{ $t('dae_grant_allocation_distribution.farmer_mobile_number') }}</b-th>
                                                    <b-th>{{ $t('farm_config.machine_name') }}</b-th>
                                                    <b-th>{{ $t('dae_subsidy.fiscal_year') }}</b-th>
                                                </b-tr>
                                                <b-tr v-for="(item, index) in duplicateRowsData" :key="index">
                                                    <b-td> {{ item.krrishker_nam }} </b-td>
                                                    <b-td>
                                                        {{ checkLanguage(item.mobail_nmbr) === 'english' ?
                                                        ($i18n.locale == 'bn' ? '০': '0') + $n(item.mobail_nmbr, { useGrouping: false }) : item.mobail_nmbr }}
                                                    </b-td>
                                                    <b-td>{{ getInstrumentName(item.instrument_id) }}</b-td>
                                                    <b-td>{{ getFiscalYearName(item.fiscal_year_id) }}</b-td>
                                                </b-tr>
                                            </b-table-simple>
                                          </b-row>

                                          <b-row>
                                            <h4 class="mb-1 text-center" v-if="skipDataShow">{{ $i18n.locale === 'en' ? 'Existing Records' : 'বিদ্যমান রেকর্ড' }}</h4>
                                            <b-table-simple bordered v-if="skipDataShow">
                                                <b-tr>
                                                    <!-- <b-th>{{ $t('globalTrans.upazila') }}</b-th> -->
                                                    <b-th>{{ $t('dae_grant_allocation_distribution.farmer_name') }}</b-th>
                                                    <b-th>{{ $t('dae_grant_allocation_distribution.farmer_mobile_number') }}</b-th>
                                                    <b-th>{{ $t('elearning_tim.name_of_father_husband') }}</b-th>
                                                    <b-th>{{ $t('dae_grant_allocation_distribution.farmer_nid') }}</b-th>
                                                    <b-th>{{ $t('dae_config.s_name') }}</b-th>
                                                    <b-th>{{ $t('dae_subsidy.fiscal_year') }}</b-th>
                                                </b-tr>
                                                <b-tr v-for="(item, index) in exitsRowsData" :key="index">
                                                    <!-- <b-td> {{ item.upjela }} </b-td> -->
                                                    <b-td> {{ item.krrishker_nam }} </b-td>
                                                    <b-td>
                                                        {{ checkLanguage(item.mobail_nmbr) === 'english' ?
                                                        ($i18n.locale == 'bn' ? '০': '0') + $n(item.mobail_nmbr, { useGrouping: false }) : item.mobail_nmbr }}
                                                    </b-td>
                                                    <b-td> {{ item.pita_swameer_nam }} </b-td>
                                                    <b-td> {{ $n(item.enaidi_nmbr, { useGrouping: false }) }} </b-td>
                                                    <b-td> {{ item.srbrahkaree_prtishthaner_nam }} </b-td>
                                                    <b-td> {{ item.orthbchr }} </b-td>
                                                </b-tr>
                                            </b-table-simple>
                                          </b-row>
                                          <b-row>
                                            <h4 class="mb-1 text-center" v-if="errorDataShow">{{ $i18n.locale === 'en' ? 'Modify Information' : 'তথ্য সংশোধন করুন' }}</h4>
                                            <b-table-simple bordered v-if="errorDataShow">
                                                <b-tr>
                                                    <!-- <b-th>{{ $t('globalTrans.upazila') }}</b-th> -->
                                                    <b-th>{{ $t('dae_grant_allocation_distribution.farmer_name') }}</b-th>
                                                    <b-th>{{ $t('dae_grant_allocation_distribution.farmer_mobile_number') }}</b-th>
                                                    <b-th>{{ $t('elearning_tim.name_of_father_husband') }}</b-th>
                                                    <b-th>{{ $t('dae_grant_allocation_distribution.farmer_nid') }}</b-th>
                                                    <b-th>{{ $t('dae_config.s_name') }}</b-th>
                                                    <b-th>{{ $t('dae_subsidy.fiscal_year') }}</b-th>
                                                </b-tr>
                                                <b-tr v-for="(item, index) in errorData" :key="index">
                                                     <!-- <b-td> {{ item.upjela }} </b-td> -->
                                                    <b-td> {{ item.krrishker_nam }} </b-td>
                                                    <b-td>
                                                        {{ checkLanguage(item.mobail_nmbr) === 'english' ?
                                                        ($i18n.locale == 'bn' ? '০': '0') + $n(item.mobail_nmbr, { useGrouping: false }) : item.mobail_nmbr }}
                                                    </b-td>
                                                    <b-td> {{ item.pita_swameer_nam }} </b-td>
                                                    <b-td> {{ $n(item.enaidi_nmbr, { useGrouping: false }) }} </b-td>
                                                    <b-td> {{ item.srbrahkaree_prtishthaner_nam }} </b-td>
                                                    <b-td> {{ item.orthbchr }} </b-td>
                                                </b-tr>
                                            </b-table-simple>
                                          </b-row>
                                          <div class="row">
                                              <div class="col-sm-3"></div>
                                              <div class="col text-right">
                                                  <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                  &nbsp;
                                                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-6')">{{ $t('globalTrans.cancel') }}</b-button>
                                              </div>
                                          </div>
                                      </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { importer, machineryFarmerUpdate } from '../../api/routes'
// import flatpickr from 'flatpickr'

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            machineryFarmer: {
                attachment: []
            },
            exitsRowsData: [],
            errorData: [],
            attachmentFile: [],
            errorDataShow: false,
            skipDataShow: false,
            duplicateDataShow: false,
            duplicateRowsData: []
        }
    },
    mounted () {
      core.index()
    },

    methods: {
        onFileChange (e) {
            this.attachmentFile = e.target.files[0]
        },
        async saveData () {
            this.errorDataShow = false
            this.duplicateDataShow = false
            this.skipDataShow = false
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            var formData = new FormData()
            Object.keys(this.machineryFarmer).map(key => {
                if (key === 'attachment') {
                formData.append(key, this.attachmentFile)
                } else {
                formData.append(key, this.machineryFarmer[key])
                }
            })
            if (this.id) {
                formData.append('_method', 'POST')
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, `${machineryFarmerUpdate}/${this.id}`, formData, config)
            } else {
                result = await RestApi.postData(incentiveGrantServiceBaseUrl, importer, formData, config)
            }

            loadingState.listReload = true
            this.$store.dispatch('mutateCommonProperties', loadingState)
            this.loading = false
            if (result.success) {
                this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                this.$toast.success({
                title: this.$t('globalTrans.success'),
                message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                color: '#D6E09B'
                })
                this.$bvModal.hide('modal-6')
            } else {
                if (result.data && result.data.length > 0) {
                    this.errorDataShow = true
                    this.errorData = result.data
                }
                if (result.exitsRows && result.exitsRows.length > 0) {
                    this.skipDataShow = true
                    this.exitsRowsData = result.exitsRows
                }
                // result.duplicateRows
                if (result.duplicateRows && result.duplicateRows.length > 0) {
                    this.duplicateDataShow = true
                    this.duplicateRowsData = result.duplicateRows
                }

                this.$toast.error({
                title: 'Error',
                message: result.message,
                color: '#D6E09B'
                })
                this.$refs.form.setErrors(result.errors)
            }
        },
        checkLanguage (inputNumber) {
                // Define patterns for English and Bangla numbers
                const englishPattern = /^[0-9]+$/
                const banglaPattern = /^[০-৯]+$/

                if (englishPattern.test(inputNumber)) {
                    return 'english'
                } else if (banglaPattern.test(inputNumber)) {
                    return 'bangla'
                } else {
                    return ''
                }
        },
        getFiscalYearName (id) {
            const obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        },
        getInstrumentName (id) {
            const obj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        }
    }
}
</script>
